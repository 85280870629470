<template>
  <div class="login-container">
    <div class="login-card">
      <div class="login-title">管理员登录</div>
      <!-- 登录表单 -->
      <el-form
        status-icon
        :model="loginForm"
        :rules="rules"
        ref="ruleForm"
        class="login-form"
      >
        <!-- 用户名输入框 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user-solid"
            placeholder="用户名"
            @keyup.enter.native="submit"
          />
        </el-form-item>
        <!-- 密码输入框 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            prefix-icon="iconfont el-icon-mymima"
            show-password
            placeholder="密码"
            @keyup.enter.native="submit"
          />
        </el-form-item>
        <el-form-item prop="code" v-if="captchaEnabled">
          <el-input
            v-model="loginForm.code"
            prefix-icon="el-icon-key"
            auto-complete="off"
            placeholder="验证码"
            style="width: 63%"
            @keyup.enter.native="submit"
          >
          </el-input>
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" class="login-code-img" />
          </div>
        </el-form-item>
        <el-checkbox
          v-model="loginForm.rememberMe"
          style="margin:0px 0px 25px 0px;"
          >记住密码</el-checkbox
        >
      </el-form>
      <!-- 登录按钮 -->
      <el-button type="primary" @click="submit">登录</el-button>
    </div>
  </div>
</template>

<script>
import VueCookie from "vue-cookie";
import { getCodeImg } from "@/api/login";
import { encrypt, decrypt } from "@/utils/jsencrypt";

export default {
  data: function() {
    return {
      // 验证码开关
      captchaEnabled: true,
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      }
    };
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaEnabled =
          res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = VueCookie.get("username");
      const password = VueCookie.get("password");
      const rememberMe = VueCookie.get("rememberMe");
      this.loginForm = {
        username: username === undefined || username == null ? this.loginForm.username : username,
        password: password === undefined || password == null ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined || rememberMe == null ? false : Boolean(rememberMe)
      };
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    login() {
      const that = this;
      this.loading = true;
      if (this.loginForm.rememberMe) {
        VueCookie.set("username", this.loginForm.username, { expires: 30 });
        VueCookie.set("password", encrypt(this.loginForm.password), {expires: 30});
        VueCookie.set("rememberMe", this.loginForm.rememberMe, { expires: 30 });
      } else {
        VueCookie.delete("username");
        VueCookie.delete("password");
        VueCookie.delete("rememberMe");
      }
      //发送登录请求
      this.$store.dispatch("Login", this.loginForm).then(res => {
          that.$router.push({ path: "/" }).catch(() => {});
      }).catch(() => {
        this.loading = false;
        if (this.captchaEnabled) {
          this.getCode();
        }
      });
    }
  }
};
</script>

<style scoped>
.login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: url(../../assets/png/green-back.jpg) center center / cover no-repeat;
}

.login-card {
  position: absolute;
  top: 30%;
  right: 60px;
  padding: 25px;
  width: 350px;
  background-color: #ffffff;
}

.login-title {
  color: #0c0c0c;
  font-weight: bold;
  font-size: 1rem;
}

.login-form {
  margin-top: 1.2rem;
}

.login-form .el-input {
  background-color: #ffffff;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;
}
.login-code img {
  cursor: pointer;
  vertical-align: middle;
}

.login-code-img {
  height: 38px;
}

.login-card button {
  margin-top: 1rem;
  width: 100%;
}
</style>
